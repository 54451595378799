body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #212121;

}

.infoBox {
  padding-top:100px;
  color:darkgray;
  text-align: center;
}

span[name="react-console-emulator__promptLabel"], input[name="react-console-emulator__input"]{
  color:steelblue !important;
}